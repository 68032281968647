// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-about-de-js": () => import("./../../../src/pages/about.de.js" /* webpackChunkName: "component---src-pages-about-de-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-e-commerce-online-shop-de-js": () => import("./../../../src/pages/e-commerce-online-shop.de.js" /* webpackChunkName: "component---src-pages-e-commerce-online-shop-de-js" */),
  "component---src-pages-e-commerce-online-shop-en-js": () => import("./../../../src/pages/e-commerce-online-shop.en.js" /* webpackChunkName: "component---src-pages-e-commerce-online-shop-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-privacy-de-js": () => import("./../../../src/pages/privacy.de.js" /* webpackChunkName: "component---src-pages-privacy-de-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-ux-ui-design-de-js": () => import("./../../../src/pages/ux-ui-design.de.js" /* webpackChunkName: "component---src-pages-ux-ui-design-de-js" */),
  "component---src-pages-ux-ui-design-en-js": () => import("./../../../src/pages/ux-ui-design.en.js" /* webpackChunkName: "component---src-pages-ux-ui-design-en-js" */),
  "component---src-pages-websites-de-js": () => import("./../../../src/pages/websites.de.js" /* webpackChunkName: "component---src-pages-websites-de-js" */),
  "component---src-pages-websites-en-js": () => import("./../../../src/pages/websites.en.js" /* webpackChunkName: "component---src-pages-websites-en-js" */)
}

